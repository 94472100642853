#page.index.index{

  .post-list{
    margin-top: 30px;
    img{
      width: 100%;
    }
      max-width: 1240px;

      .left{
        height: 410px;
        width: 630px;
        float: left;
        p{
          font-size: 24px;
          height: 30px;
        }
      }
    .post-img{
      position: relative;
      p{
        position: absolute;
        bottom: -16px;
        background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1));
        overflow: hidden;
        height:40px;
        line-height: 20px;
        color: #fff;
        text-align: center;
        width: 100%;
      }
    }

      .right{
        height: 410px;
        width: 610px;
        float: left;
        .post-img{
          width: 295px;
          float: left;
          margin-left: 9px;
          p{
            height: 24px;
            font-size: 18px;
          }

        }
        .top,.down{
          width: 640px;
          height: 200px;
        }
        .down{
          .post-img{
            margin-top: 8px;
          }
        }
      }

  }

  .brand-list{
    max-width: 1240px;
    background-color: #f7f7f7;
    margin-top: 20px;
    .col{
      .name{
        height:20px;
        overflow: hidden;
        font-size: 12px;

      }
      p{
        margin-bottom: 0px;
        img{
          width: 60%;
        }
      }
      border-right: 1px solid #ffffff;
      text-align: center;
      padding-bottom: 5px;
    }
    .more{
      padding-top: 15px;
    }

  }
  .slide-news{
    max-width: 1270px;
    margin-top: 30px;
    img{
      width: 100%;
      overflow: hidden;
    }
    .left{
      img{
        height:440px;
      }
    }

    .right{
      img{
        height:190px;
      }
    }
    p{
      position: relative;
      background-color: rgba(0,0,0, 0.3);
      color: #ffffff;
      padding: 6px;
      display: inline-block;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow:ellipsis;
    }
    .col-6{
      .col{
        img{
          height: 170px;
        }
      }
    }


  }
  .text-list{
    max-width: 1270px;
    margin-top: 40px;
    .text-item{
      margin-bottom: 20px;
    }
    .two-text{
      margin: 0;
      padding-top: 8px;
    }
    .img{
      position: relative;
      img{
        width: 100%;
      }
      .post-title{
        position: absolute;
        bottom: -11px;
        width: 86%;
        font-size: 14px;
        height: 20px;
        color: #fff;
        text-align: center;
        overflow: hidden;
        background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1));

      }

    }
    h5{
      font-size: 18px;
      font-weight: bolder;
    }
  }
  .news-list{
    max-width: 1270px;
    margin-top: 30px;
    .title {
      border-left: 4px solid #ffa100;
      padding-left: 10px;
      font-weight: bold;
      margin-bottom: 20px;
      .main{
        font-size: 20px;
        display: block;
        padding-right: 10px;

      }
      .item{
        font-weight: 400;
        font-size: 18px;
        padding-left: 15px;
        display: block;
        padding-top: 3px;
        padding-right: 10px;
        cursor: pointer;
        color: #333;
        font-weight: bold;
      }
      .active{
        font-weight: bold;
        color: #000;
      }
    }
    .image-news{
      img{
      }
      border-bottom: 1px solid #eee;
      margin-bottom: 25px;
    }

    a{
      color: #333333;
    }
  }

  .video-list{
    p{
      text-align: center;
    }
    img{
      width: 100%;
    }
  }

  .more-news{
    padding-top: 20px;
    margin-top: 20px;
    margin-left: 20px;
    border-top: 1px solid #e4e4e4;
    background-color: #eee;
    cursor: pointer;
  }
}
#page.index.ev .brand-list .col p img{
  width: 100%;
}
#page.index.auto,#page.index.image{

  .master-list{
    p{
      margin-left: 30px;
      padding-left: 10px;
      border-bottom: 1px solid #eee;
    }

  }
  .car-title{
    max-width: 1240px;
    font-size: 30px;
    margin-top: 40px;
    border-bottom: 1px solid #999;
  }
  .car-content{
    max-width: 1240px;
    .master-list{
      overflow: auto;
      height: 500px;
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */
      overflow-x: hidden;
      overflow-y: auto;
      margin-top: 20px;
    }

    .serial-list{
      margin-top: 20px;
    }
  }
}